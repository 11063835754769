import React, {useState, useEffect} from "react";
import Header from "./Header1";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link, NavLink, useNavigate } from "react-router-dom";

function Career() {
      const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [jobid, setJobid]=useState([])

  const addForm =(data)=>{
    setShow(true)
    setJobid(data)
  }

  const [form, setform] = useState({
    fullName: "",
    email: "",
    phone: "",
    description: "",
  });
  const [file, setFile] = useState("");

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const fileChange = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "pdf" || type == "word") {
      setFile(e.target.files);
    } else {
      e.target.value = null;
      toast.error("Please select (pdf, word)");
    }

  };


  const [jobs, setJobs]=useState([])

  const jobsData =()=>{
    axios.post("https://api.digitalraiz.co.in/api/user/applyjob/getalljobsforweb", {}).then((res)=>{
      setJobs(res.data.jobResult)
    })
  }

  useEffect(()=>{
    jobsData()
  }, [])

  const addUsers = () => {
    const dataArray = new FormData();
    dataArray.append("fullName", form.fullName);
    dataArray.append("jobId", jobid._id);
    dataArray.append("phone", form.phone);
    dataArray.append("email", form.email);
    dataArray.append("description", form.description);
    for (let i = 0; i < file.length; i++) {
      dataArray.append("doc", file[i]);
    }
    axios
      .post("https://api.digitalraiz.co.in/api/user/applyjob/addapplyjob", dataArray, {})
      .then((res) => {
        console.log("success");
        toast.success(
          "Added Successfully. Your data received, Our team will get back to you soon!"
        );
        // userdata();
        handleClose();
        jobsData()
      });
  };

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleDescription = (index) => {
    setExpandedIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  return (
    <div>
      <Header />
      <section className="mt-5 pt-5 mb-5">
        <div className="container mt-5 pt-5">
          {/* <div className="section-header">
            <h2>Career with DigitalRaiz</h2>
            <p>
              Find Career Opportunities, Jobs, and Employment. Over the years,
              we have assisted a few businesses in hiring top talent.
            </p>
          </div> */}
          <div className="row">
            {jobs.map((data, index)=>(
            <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-5">
              <div className="cardsty">
                <div className="bg-white shadow border-radius-11">
                  <div className="d-flex mb-3 row">
                    <div className="col-8">
                      <h4>{data.title} </h4>
                      {/* <p>Joy International School</p> */}
                    </div>
                    <div className="col-4 ">
                      <div className="">
                        <button
                          onClick={() => {
                            addForm(data);
                          }}
                          style={{ marginTop: "0px" }}
                          className="btn theme-btn btn-block"
                        >
                          <span className="btna text-white">Apply Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mt-2">
                        <ul className="list-unstyled mb-0 text-start">
                          {data.addMore.map((data, key)=>(
                          <li key={key} className="d-inline-block  m-1 p-1">
                            <a className="badgesty">
                              {/* <i className="fa fa-briefcase" /> */}
                             {data.label}
                            </a>
                          </li>
                       ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: expandedIndexes.includes(index)
                      ? data.description
                      : `${data.description.slice(0, 250)}...`,
                  }}
                ></div>
                <a 
                  onClick={() => toggleDescription(index)}
                  className="btnlink"
                >
                  {expandedIndexes.includes(index) ? "View Less" : "View More"}
                </a>
              </div>

                  {/* <div className="mt-4"
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  ></div> */}
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </section>

      <div className="copyrights mt-5">
            <p>
              Copyright © {new Date().getFullYear()}{" "}
              <a target="_blank" style={{color:"#8b27cc"}} href="https://digitalraiz.co.in/">
                DigitalRaiz{" "}
              </a>
              <span> All Rights Reserved</span>
            </p>
          </div>
      <Modal
        // size="md"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="mt-5"
      >
        {/* <div onClick={handleClose}>
          <i class="fa fa-times modalstyle " aria-hidden="true" ></i>
        </div> */}
        <div className="row login-page ">
          <div className="col-md-12">
            <div className="container">
              {/* <a href="" className="res-logo">
                <img src={logo} alt="Logo" />
              </a> */}
              <div className="login-form modheight">
                {/* <div className="login-form-head ">
                  <h2 className="mt-5">
                    <span className="h2txt">Welcome to</span>{" "}
                    <span>DigitalRaiz</span>
                  </h2>
                  <p>Fill out the form to get started..</p>
                </div> */}
                <form className="mt-3" onSubmit={usersSubmit}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinEmail">
                      Full Name
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-user-o" />
                      </div>
                      <input
                        type="text"
                        pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                        className="form-control"
                        name="fullName"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        id="signinEmail"
                        placeholder="Full Name"
                        aria-label="Email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      Mobile
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-mobile" />
                      </div>
                      <input
                        type="text"
                        minlength="0"
                        maxlength="10"
                        pattern="\d{10}"
                        className="form-control"
                        name="phone"
                        id="signinPassword"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Mobile No..."
                        aria-label="Password"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      Email
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div>
                      <input
                        type="email"
                        minlength="0"
                        // maxlength="10"
                        // pattern="\d{10}"
                        className="form-control"
                        name="email"
                        id="signinPassword"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Email"
                        aria-label="Password"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      Resume
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-file-text-o" />
                      </div>
                      <input
                        type="file"
                        // minlength="0"
                        // maxlength="10"
                        // pattern="\d{10}"
                        className="form-control"
                        name="doc"
                        id="signinPassword"
                        onChange={fileChange}
                        
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinEmail">
                    Cover Letter
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-commenting-o mr-3" />
                      </div>
                      <textarea
                        type="text"
                        className="form-control1 w-100"
                        name="description"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        id="signinEmail"
                        placeholder="Cover Letter"
                        aria-label="Cover Letter"
                        required
                      />
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"> Email Id</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div>
                      <input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" name="email" id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder="Email Id" aria-label="Password" required />
                    </div>
                  </div> */}
                
                  
                  <div className="row mb-3">
                    <div className="col-md-4" />
                    <div className="col-4">
                    <button  onClick={handleClose} className="btn btn-danger w-100  m-2" type="button">
                    Cancel
                    </button>
                    </div>
                    <div className="col-4">
                    <button  className="btn theme-btn w-100 m-2" type="submit">
                      Submit
                    </button>
                    </div>
                  </div>
                
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default Career;
