import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../assets/css/style.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import per1 from "../assets/images/per1.png";
import per2 from "../assets/images/per2.png";
import per3 from "../assets/images/per3.png";
import per4 from "../assets/images/per4.png";
import per5 from "../assets/images/per5.png";

function Footer(props) {
  const navigate = useNavigate();
  const textInput = React.useRef();
  const textInput1 = React.useRef();
  const textInput2 = React.useRef();
  const textInput3 = React.useRef();

  const [form, setform] = useState([]);

  const clearInput = () => {
    textInput.current.value = "";
    textInput1.current.value = "";
    textInput2.current.value = "";
    textInput3.current.value = "";
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const addUsers = () => {
    var params = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      service: form.service,
    };
    axios
      .post("https://api.digitalraiz.co.in/api/user/leads/addLead", params, {})
      .then((res) => {
        console.log("success");
        toast.success(
          "Added Successfully. Your data received, Our team will get back to you soon!"
        );
        navigate("/thankyou");
      });
  };

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
    clearInput();
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // datas();
  }, []);

  const api_url = "https://api.digitalraiz.co.in";

  return (
    <div style={{ overflowY: "hidden" }}>
      <footer className="footerstyle">
        <div className="shape-top" />
        <div className="container">
          <div className="top-footer">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-logo"></div>

                <div>
                  <h4
                    className="footer-title"
                    style={{ textDecoration: "underline" }}
                  >
                    Google reviews
                  </h4>
                  <Slider {...settings1}>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img
                            src={per1}
                            className="rounded-circle"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </Col>
                        <Col md={8}>
                          <h6 style={{ color: "white" }}>Koteswarrao</h6>
                        </Col>
                      </Row>
                      <p>
                        This company giving is the best development services for
                        mobile apps. They make design and development for mobile
                        apps are really so nice.
                      </p>
                      <div style={{ color: "yellow  " }}>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img
                            src={per2}
                            className="rounded-circle"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginBottom: "0px",
                            }}
                          ></img>
                        </Col>
                        <Col md={8}>
                          <h6 style={{ color: "white" }}>Velpula Ramu</h6>
                        </Col>
                      </Row>
                      <p>
                        I want a very good website for my company growth I am
                        visiting Digital Raiz they are very friendly and fast I
                        am satisfied their work
                      </p>
                      <div style={{ color: "yellow  " }}>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img
                            src={per4}
                            className="rounded-circle"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginBottom: "0px",
                            }}
                          ></img>
                        </Col>
                        <Col md={8}>
                          <h6 style={{ color: "white" }}>Akhil Muthyala</h6>
                        </Col>
                      </Row>
                      <p>
                        This is one of top best software company in Hyderabad. I
                        didn't seen ever this type of team work.
                      </p>
                      <div style={{ color: "yellow  " }}>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img
                            src={per3}
                            className="rounded-circle"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginBottom: "0px",
                            }}
                          ></img>
                        </Col>
                        <Col md={8}>
                          <h6 style={{ color: "white" }}> Deeraj Reddi</h6>
                        </Col>
                      </Row>
                      <p>
                        This is one of the best software company in KPHP in
                        Hyderabad. I heard that This company giving excellent
                        services on Website Development.
                      </p>
                      <div style={{ color: "yellow  " }}>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img
                            src={per5}
                            className="rounded-circle"
                            style={{
                              width: "50px",
                              height: "50px",
                              marginBottom: "0px",
                            }}
                          ></img>
                        </Col>
                        <Col md={8}>
                          <h6 style={{ color: "white" }}>Nikhil</h6>
                        </Col>
                      </Row>
                      <p>
                        Top Web Design and Developers have in this Software
                        Company. My Website is working fastly with best
                        effective response have. Thank you DigitalRaiz Team.
                      </p>
                      <div style={{ color: "yellow  " }}>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>

              <div className="col-md-3">
                <h4
                  class="footer-title"
                  style={{ textDecoration: "underline" }}
                >
                  DigitalRaiz Placed
                </h4>
                <ul className="footer-links">
                  <marquee
                    class="marquee_info col-md-10 d-flex justify-content-center"
                    width="80%"
                    behavior="scroll"
                    direction="up"
                    scrollamount="2"
                    onmouseover="this.stop();"
                    onmouseout="this.start();"
                  >
                    {props.data17.map((data, i) => {
                      return (
                        <>
                          <div key={i} style={{ textAlign: "center" }}>
                            <img
                              src={api_url + "/" + data.image}
                              style={{ height: "40px", width: "80px" }}
                              className="mt-3"
                            />
                          </div>
                        </>
                      );
                    })}
                  </marquee>
                </ul>
              </div>

              <div className="col-md-3">
                <h4
                  className="footer-title"
                  style={{ textDecoration: "underline" }}
                >
                  {" "}
                  {props.data18.title}
                </h4>
                <ul className="footer-links">
                  <li className="ml-2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3282135311542!2d78.393591!3d17.491839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91c70d3f2329%3A0xe69263b460c8fae6!2sDigitalRaiz%20Creative%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1722832248930!5m2!1sen!2sin"
                      width={220}
                      height={100}
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </li>
                  <li>
                    <a
                      href="https://www.google.com/maps/place/DigitalRaiz+Creative+Solutions+Pvt+Ltd/@17.491839,78.3910161,17z/data=!3m2!4b1!5s0x3bcb91921221fff5:0xe760294af44a0759!4m6!3m5!1s0x3bcb91c70d3f2329:0xe69263b460c8fae6!8m2!3d17.491839!4d78.393591!16s%2Fg%2F11j8w513c1?entry=ttu"
                      target="_blank"
                    >
                      <i
                        class="fa fa-map-marker mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      {props.data18.address}
                    </a>
                  </li>
                  <li>
                    <a href={`tel:+91 9494613601,+91 9494613601`}>
                      <i
                        className="fa fa-mobile mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      +91 9110 724 571, +91 9494 613 601
                    </a>
                  </li>

                  <li>
                    <a
                      href={`mailto:${props.data18.emailOne},${props.data18.emailTwo}`}
                    >
                      <i
                        className="fa fa-envelope-o mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      info@digitalraiz.com
                    </a>
                    <a
                      href={`mailto:support@digitalraiz.com`}
                      style={{ marginBottom: "0px", marginLeft:"20px" }}
                    >
                      <i
                        className="fa fa-envelope- ml-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      support@digitalraiz.com
                    </a>
                    <a
                      href={`mailto:digitalraizinst@gmail.com`}
                      style={{ marginBottom: "0px", marginLeft:"20px" }}
                    >
                      <i
                        className="fa fa-envelope- ml-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      digitalraizinst@gmail.com
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <h4
                  className="footer-title"
                  style={{ textDecoration: "underline" }}
                >
                  Enquiry{" "}
                </h4>
                <ul className="footer-links">
                  <form onSubmit={usersSubmit}>
                    <input
                      type="text"
                      pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                      className="form-control"
                      name="name"
                      ref={textInput}
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      id="signinEmail"
                      placeholder="Full Name"
                      aria-label="Email address"
                      required
                    />

                    <input
                      type="text"
                      maxlength="10"
                      pattern="\d{10}"
                      className="form-control"
                      name="phone"
                      ref={textInput1}
                      id="signinPassword"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      placeholder="Mobile No..."
                      aria-label="Password"
                      required
                    />

                    <input
                      type="email"
                      pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)"
                      className="form-control"
                      name="email"
                      ref={textInput2}
                      id="signinPassword2"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      placeholder="Email Id"
                      aria-label="Password"
                      required
                    />
                    <select
                      className="form-control"
                      name="service"
                      ref={textInput3}
                      id="signinPassword2"
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      required
                    >
                      <option value="">Services</option>
                      <option
                        className="form-control"
                        value="Mobile Application"
                      >
                        Mobile Application
                      </option>
                      <option
                        className="form-control"
                        value="Website Design & Development"
                      >
                        Website Design & Development
                      </option>
                      <option
                        className="form-control"
                        cvalue="Digital Marketing"
                      >
                        Digital Marketing
                      </option>
                      <option className="form-control" cvalue="Others">
                        Others
                      </option>
                    </select>
                    <div style={{ float: "right" }}>
                      <button class="btn theme-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyrights">
            <p>
              Copyright © {new Date().getFullYear()}{" "}
              <a target="_blank" href="https://digitalraiz.co.in/">
                DigitalRaiz{" "}
              </a>
              <span> All Rights Reserved</span>
            </p>
          </div>
        </div>
      </footer>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default Footer;
