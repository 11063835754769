import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// import { Card, CardBody } from "reactstrap";
import { Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactPaginate from "react-paginate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function Testimonial() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setform] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [user1, setuser1] = useState([]);
  const [user, setuser] = useState([]);

  const [inputList, setInputList] = useState([{ label: "" }]);
  const [inputList2, setInputList2] = useState([{ label: "" }]);
  const [text1, setText1] = useState([]);
  const [text2, setText2] = useState([]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    setInputList2(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleRemoveClick2 = (index) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { label: "" }]);
  };
  const handleAddClick2 = () => {
    setInputList2([...inputList2, { label: "" }]);
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addCategory();
  };

  useEffect(() => {
    getCategory();
  }, []);

  const  token = sessionStorage.getItem("token");
  console.log(token)
  const getCategory = () => {
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/job/getalljobs",{},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.jobResult);
      });
  };

  const api_url = "https://api.digitalraiz.co.in";

  const addCategory = () => {
    const data = {
        title: form.title,
        description: text1,
        addMore: inputList,
    };
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/job/addjob",
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose();
            getCategory()
            setText1([])
            setInputList([{ label: "" }])

          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleChange1 = (e) => {
    let myUser = { ...user1 };
    myUser[e.target.name] = e.target.value;
    setuser1(myUser);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    updateCategory();
  };

  const getpopup = (data) => {
    setuser1(data);
    setText2(data.description)
    setInputList2(data.addMore)
    handleShow1();
    // handleSubmit1(data);
  };

  const updateCategory = () => {
    const data1 = user1._id;
    const data = {
      title: user1.title,
      description: text2,
      addMore: inputList2,
      status: user1.status,
    };
    axios
      .put(
        "https://api.digitalraiz.co.in/api/admin/job/editjob" +
          "/" +
          data1,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose1();
            getCategory();
         
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const manageDelete = (data) => {
    const confirmBox = window.confirm("Do you really want to Delete?");
    if (confirmBox === true) {
      deleteUser(data);
    }
  };

  const deleteUser = (data) => {
    const data1 = data._id;
    console.log(data1);
    axios
      .delete(
        "https://api.digitalraiz.co.in/api/admin/job/removeJob" +
          "/" +
          data1,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };
  const [search, setsearch] = useState("");
  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = user.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(user.length / listPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const clearForm = () => {
    setform({
      title: "",
      description: "",
    });
  };

  return (
    <div>
      <Box
        sx={{ display: "flex" }}
        style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
        className="cardmrg"
      >
        <Drawer></Drawer>
        <CssBaseline />
        <Box
          component="main"
          style={{ background: "#ebeaea" }}
          sx={{ flexGrow: 2, p: 4 }}
        >
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Jobs</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-3"
                      onClick={handleShow}
                    >
                      {" "}
                      + Add New One
                    </button>
                  </span>
                </div>
              </div>
              <div className="table-responsive mt-3">
                <Table
                  id="table-to-xls"
                  bordered
                  className="table-centered datatable dt-responsive nowrap "
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                  }}
                >
                  <thead
                    className="thead-light"
                    style={{ background: "#1562a0", color: "white" }}
                  >
                    <tr>
                      <th>Sl.no</th>
                      <th  style={{ width: "130px" }}>Date</th>
                      <th>Job Title</th>
                      <th>Keys </th>
                      <th>Status</th>
                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {" "}
                    {lists.map((data, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{(pageNumber - 1) * 10 + i + 11}</td>
                            <td>{data.date}</td>
                            <td>{data.title}</td>
                            <td>{data.addMore.map((data)=>(data.label + ", "))}</td>
                            <td>
                              {data.status}
                            </td>
                            <td>
                              <EditIcon
                                onClick={() => {
                                  getpopup(data);
                                }}
                                style={{ fontSize: "30px", color: "black" }}
                              />
                              <DeleteForeverIcon
                                onClick={() => {
                                  manageDelete(data);
                                }}
                                style={{ fontSize: "30px", color: "red" }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="mt-3" style={{ float: "right" }}>
                <a style={{ cursor: "pointer" }}>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                </a>
              </div>
              <ToastContainer></ToastContainer>
            </CardBody>
          </Card>
        </Box>
      </Box>
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          style={{ marginTop: "80px" }}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>Add</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div class="container">
                <div>
                  <label>Title: </label>
                  <input
                    type="text"
                    className="form-control uuu"
                    placeholder="Enter title"
                    required
                    name="title"
                    value={form.title}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <div>
                  <label>Job Keys</label>
                  <div>
                    {inputList.map((x, i) => {
                      return (
                        <div key={i} className="box row">
                          <div md="5" sm="12" className="mb-1 col">
                            <input
                              type="text"
                              id="nameMulti"
                              placeholder="Enter Name"
                              className="form-control uuu"
                              name="label"
                              value={x.label}
                              onChange={(e) => handleInputChange(e, i)}
                              required
                            />
                          </div>
                          <div sm="3" className="col">
                            <div className="btn-box">
                              {inputList.length !== 1 && (
                                <button
                                  className="mr10 btn btn-danger btn-sm m-1"
                                  type="button"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove <i className="bx bx-x-circle"></i>
                                </button>
                              )}
                              {inputList.length - 1 === i && (
                                <button
                                  className="btn btn-sm btn-info m-1"
                                  onClick={handleAddClick}
                                >
                                  Add <i className="bx bx-plus-circle"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <label htmlFor="placeholderInput" className="form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="header"
                    data={text1}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText1(data);
                    }}
                  />
                </div>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={show1} onHide={handleClose1} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit1(e);
              }}
            >
              <div class="container">
                <div>
                  <label>Title: </label>
                  <input
                    type="text"
                    className="form-control uuu"
                    placeholder="Enter title"
                    required
                    name="title"
                    value={user1.title}
                    onChange={(e) => {
                      handleChange1(e);
                    }}
                  />
                </div>
                <div>
                  <label>Job Keys</label>
                  <div>
                    {inputList2.map((x, i) => {
                      return (
                        <div key={i} className="box row">
                          <div md="5" sm="12" className="mb-1 col">
                            <input
                              type="text"
                              id="nameMulti"
                              placeholder="Enter Name"
                              className="form-control uuu"
                              name="label"
                              value={x.label}
                              onChange={(e) => handleInputChange2(e, i)}
                              required
                            />
                          </div>
                          <div sm="3" className="col">
                            <div className="btn-box">
                              {inputList2.length !== 1 && (
                                <button
                                  className="mr10 btn btn-danger btn-sm m-1"
                                  type="button"
                                  onClick={() => handleRemoveClick2(i)}
                                >
                                  Remove <i className="bx bx-x-circle"></i>
                                </button>
                              )}
                              {inputList2.length - 1 === i && (
                                <button
                                  className="btn btn-sm btn-info m-1"
                                  onClick={handleAddClick2}
                                >
                                  Add <i className="bx bx-plus-circle"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <label htmlFor="placeholderInput" className="form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="header"
                    data={text2}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setText2(data);
                    }}
                  />
                </div>

                <label className="mt-3">Status :</label>
                <select
                  className="form-control form-select uuu"
                  required
                  name="status"
                  value={user1.status}
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                >
                  <option value="">select</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose1}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <ToastContainer />
      </div>
    </div>
  );
}

export default Testimonial;
