import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import logo from "../assets/images/newimgs/logo.png";
// import logo from '../assets/images/logo.png'
import author1 from "../assets/images/blog/author-1.jpg";
import author2 from "../assets/images/blog/author-2.jpg";
import author3 from "../assets/images/blog/author-3.jpg";
import author4 from "../assets/images/blog/author-4.jpg";
// import author5 from "../assets/images/blog/author-5.jpg";
import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from "react-hot-toast";
import "../assets/css/style.css";
import DigitalRaizpdf from "../assets/images/office/DIGITALRAIZ PROFILE.pdf";
import DigitalraizCreativeSolutions from "../assets/images/office/DIGITAL MARKETING PORTFOLIO.pdf";
import { Link, NavLink, useNavigate } from "react-router-dom";

function Header() {
  const [sticky, setSticky] = useState("");

  useEffect(() => {
    // handleShow();
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 120 ? "header-area sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `${sticky}`;

  return (
    <div>
      <header className={classes} id="header-area">
        <nav className="navbar navbar-expand-md fixed-top">
          <div className="container">
            <div className="row" style={{ width: "100%" }}>
              <div className="col-xl-6 col-lg-6 col-md-4 col-sm-3 col-3 ">
                <div className="">
                  <NavLink to="/" className="navbar-brand">
                    <img
                      src={logo}
                      className="img-fluid moblogoimg"
                      alt="Img"
                    />
                  </NavLink>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-8 col-sm-9 col-9 ">
                {/* <a className="btn themes-btn btn-block headbtn" style={{border:"none",background:"none"}}><a  className=''><i class="fa fa-phone mr-1" aria-hidden="true"></i>+91 9494613601</a></a> */}
                <div className="">
                  {/* <p> <i class="fa fa-phone mr-1" aria-hidden="true"></i>+91 9494613601</p> */}
                  <div className="row mt-3 mobtab">
                    {/* <div
                      className="col-3 mobileslider p-1"
                      style={{ marginTop: "10px", fontSize: "14px" }}
                    >
                      <a
                        href="tel:+91 9494613601"
                        className="contactnumber blink2"
                      >
                        {" "}
                        <i class="fa fa-phone mr-1 " aria-hidden="true"></i>+91
                        9494 613 601
                      </a>
                    </div> */}

                    <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 p-1">
                      <div className="control-form">
                        <a href={DigitalRaizpdf} target="_blank">
                          <button className="btn themes-btn btn-block ">
                            <a className="btna">
                              Company Profile{" "}
                              <i
                                class="fa fa-cloud-download ml-1"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </button>
                        </a>
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3  p-1">
                      <div className="control-form ">
                        <a href={DigitalraizCreativeSolutions} target="_blank">
                          <button className="btn theme-btn btn-block">
                            <span className="btna text-white">
                              Portfolio{" "}
                              <i
                                class="fa fa-arrow-right ml-1"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </button>
                        </a>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mobileslider  p-1">
                      <div className="control-form btnmar ">
                        <Link to="career">
                          <button className="btn theme-btn btn-block headbtn1">
                            <span className="btna text-white">
                              Career{" "}
                              <i
                                class="fa fa-arrow-right ml-1"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </button>
                        </Link>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
